.page-title{
  @include font($font-ubuntu, 45px, $anem-blue-header, 500);
  line-height: 51px;
  margin-bottom: 0px;
  @include breakpoint($max-medium){
    font-size: 30px;
  }
}

.panel-title{
  @include font($font-ubuntu, 30px, $anem-title, 400);
  line-height: 34px;
  margin-bottom: 0px;

  &:after{
    content: '';
    display: block;
    height: 5px;
    width: 150px;
    background-color: $anem-button;
    margin: 15px 0px 30px 0px;

    @include breakpoint($max-small){
      margin: 15px 0px 20px 0px;
    }
  }
}

.panel-title-medium{
  @include font($font-ubuntu, 20px, $anem-title, 400);
  line-height: 24px;
  margin-bottom: 0px;

  @include breakpoint($max-medium) {
    font-size: 15px;
    line-height: 19px;
  }
}

.small-title {
  @include font($font-roboto, 20px, $anem-title, 700);
  line-height: 24px;
}

.label{
  @include font($font-roboto, 14px, $anem-title, 700);
  line-height: 16px;
}

.two-points-after{
  @include font($font-roboto, 14px, $anem-title, 400);
  line-height: 16px;
}

.statut-venir{
  font-weight: 700;
}

.statut-realise{
  font-weight: 700;
  color: $anem-realise-color;
  display: flex;
  align-items: center;

  &:after{
    content: '';
    margin: 0 5px;
    display: block;
    background: url('/build/img/valide.png') no-repeat;
    width: 19px;
    height: 14px;
  }
}

.statut-supprime{
  font-weight: 700;
  color: $anem-supprime-color;
}

.statut-refuser{
  font-weight: 700;
  color: $red-refuser;
  display: flex;
  align-items: center;

  &:after{
    content: '';
    margin: 0 5px;
    display: block;
    background: url('/build/img/refuse.png') no-repeat;
    background-size: contain;
    width: 14px;
    height: 14px;
  }
}

.statut-attente{
  font-weight: 700;
  color: $orange-attente;
  display: flex;
  align-items: center;

  &:after{
    font-family: "Font Awesome 5 Free";
    content: '\f017';
    margin: 0 5px;
    font-weight: 400;
    display: block;
    font-size: 18px;
  }
}

.statut-accepter{
  font-weight: 700;
  color: $anem-realise-color;
  display: flex;
  align-items: center;

  &:after{
    content: '';
    margin: 0 5px;
    display: block;
    background: url('/build/img/valide.png') no-repeat;
    width: 19px;
    height: 14px;
  }
}


.budget-global{
  @include font($font-roboto, 20px, $anem-title, 700);
  line-height: 24px;
  @include breakpoint($max-small) {
    font-size: 15px;
    line-height: 19px;
  }
}
.budget-info{
  @include font($font-roboto, 14px, $anem-title, 400);
  line-height: 16px;
  font-style: italic;
}
.budget-label{
  @include font($font-roboto, 16px, $anem-title, 400);
  line-height: 19px;
}
.budget-label-price{
  @include font($font-roboto, 16px, $anem-title, 700);
  line-height: 19px;
}
.budget-ajustement{
  @include font($font-ubuntu, 20px, $anem-blue-header, 500);
  line-height: 23px;
  text-transform: uppercase;
  font-style: italic;
}

.isDisabled {
  display: inline-block;  /* For IE11/ MS Edge bug */
  pointer-events: none;
  text-decoration: none;
  cursor: not-allowed;
  opacity: 0.5;
}

.text-bold-blue {
  @include font($font-roboto, 14px, $anem-button, 700);
  line-height: 16px;
}

.text-blue {
  @include font($font-roboto, 14px, $anem-button, 400);
  line-height: 16px;
}

.text {
  @include font($font-roboto, 14px, $anem-title, 400);
  line-height: 16px;
}

.text-bold {
  @include font($font-roboto, 14px, $anem-title, 700);
  line-height: 16px;
}

.text-js {
  word-break: break-all;
}