.dropdown-hover-mouse{
  position: relative;

  &:hover .dropdown-menu{
    display: block;
    right: 0;
    top: 74px;
    margin: 0;
  }

}