@import "~bootstrap";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import "mixins";
@import "breakpoint/breakpoint";
@import "vars";
@import "hidden";

@import "components/buttons";
@import "components/text";
@import "components/tables";
@import "components/dropdown";
@import "components/popup";

@import "layout/header";
@import "layout/sidebar";
@import "layout/footer";
@import "layout/forms";

@import "contenu";
@import "select2";
@import "datepicker";
@import "chartjs";

body {
  background: $anem-background;
  font-size: 14px;



  @include breakpoint($max-small) {
    &.active-sidebar-mobile {
      overflow: hidden;
    }
  }
}

.overflow-hidden {
  overflow: hidden;
}

.wrapper {
  display: flex;
  align-items: stretch;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
  width: 100%;
  padding: 40px 60px;
  transition: all 0.3s;
  min-height: 70vh;

  @include breakpoint($max-medium) {
    padding: 15px;
  }
}

.content-login{
  height: calc(100vh - 125px);
  display: flex;
  justify-content: center;
  align-items: center;



  & .width-form-login{
    margin-top: -15px;
  }
}

.width-form-login{
  width: 40%;

  @include breakpoint($max-large) {
    width: 50%;
  }

  @include breakpoint($max-medium) {
    width: 70%;
  }

  @include breakpoint($max-small) {
    width: 100%;
  }
}

.nav-top {
  margin-bottom: 0;
}

.nav-bottom {
  margin-bottom: 0;
  background-color: $anem-footer-menu;
}

/* ---------------------------------------------------
    PANEL STYLE
----------------------------------------------------- */
.panel-padding {
  padding: 20px 50px;
  @include breakpoint($max-small) {
    padding: 10px;
  }
}

.panel-padding-top {
  padding: 20px 50px 0px 50px;
  @include breakpoint($max-small) {
    padding: 10px;
  }
}

.panel-padding-table-bottom {
  padding: 0px 0px 20px 0px;
}

.panel-padding-bottom {
  padding: 0px 50px 20px 50px;
}

.navigation .pagination {
  justify-content: center;

  & .page-item .page-link {
    color: $anem-blue-header;
  }

  & .page-item.active .page-link {
    background-color: $anem-blue-header;
    border-color: $anem-blue-header;
    color: white;
  }

  & .page-item.disabled .page-link {
    color: #6c757d;
  }
}

.panel-content {
  margin-bottom: 20px;
  width: 100%;
}

.panel-content-grey {
  border: 1px solid #F4F4F4;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #F4F4F4;
  width: 100%;
  margin: 0 15px 20px 15px;
}

.panel {
  background-color: white;
  margin-bottom: 20px;

  .panel-header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include breakpoint($max-small) {
      flex-wrap: wrap;
      margin-bottom: 15px;
    }
  }
}

.panel-recap-ref {
  border: 1px solid $anem-title;
  width: 49%;
  padding: 10px 20px;
  margin-bottom: 20px;

  @include breakpoint($max-medium){
    width: 100%;
  }
}

.panel-stick{
  display: flex;

  @include breakpoint($max-small) {
    flex-wrap: wrap;
  }

  & .panel-stick-w-left {
    width: 80%;

    @include breakpoint($max-extra-large) {
      width: 75%;
    }
    @include breakpoint($max-large) {
      width: 70%;
    }
    @include breakpoint($max-medium) {
      width: 50%;
    }
    @include breakpoint($max-small) {
      width: 100%;
    }
  }

  & .panel-stick-w-right {
    width: 20%;

    @include breakpoint($max-extra-large) {
      width: 25%;
    }
    @include breakpoint($max-large) {
      width: 30%;
    }
    @include breakpoint($max-medium) {
      width: 50%;
    }
    @include breakpoint($max-small) {
      width: 100%;
    }

  }

}

.panel-budget {
  display: flex;
  justify-content: space-between;

  @include breakpoint($max-small) {
    flex-wrap: wrap;
  }

  .panel-budget-left {
    width: 84%;

    &.border-budget-left {
      border: 1px solid $anem-title;
    }

    @include breakpoint($max-extra-large) {
      width: 74%;
    }
    @include breakpoint($max-large) {
      width: 64%;
    }
    @include breakpoint($max-medium) {
      width: 50%;
    }
    @include breakpoint($max-small) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .panel-budget-right {
    width: 15%;

    .panel-budget-right-percent {
      background-color: $graph-gris;
    }

    @include breakpoint($max-extra-large) {
      width: 25%;
    }
    @include breakpoint($max-large) {
      width: 35%;
    }
    @include breakpoint($max-medium) {
      width: 49%;
    }
    @include breakpoint($max-small) {
      width: 100%;
    }
  }
}

.panel-budget-secondStep {
  display: flex;
  justify-content: space-between;

  @include breakpoint($max-large) {
    flex-wrap: wrap;
  }

  .budgetPerUnion-container {
    border: 1px solid $anem-title;
  }

  .panel-budget-left {
    width: 84%;

    &.border-budget-left {
      border: 1px solid $anem-title;
    }

    @include breakpoint($max-extra-large) {
      width: 74%;
    }
    @include breakpoint($max-large) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .panel-budget-right {
    width: 15%;

    .panel-budget-right-percent {
      background-color: $graph-gris;
    }

    .panel-budget-right-history {
      @include font($font-roboto, 14px, $anem-button, 500);
      text-align: center;
      border: 1px solid $anem-button;
    }

    @include breakpoint($max-extra-large) {
      width: 25%;
    }
    @include breakpoint($max-large) {
      width: 100%;
      order: -1;
    }
  }
}

.budget-secondstep-total {
  @include font($font-roboto, 20px, white, 400);
  background-color: $anem-button;
  padding: 15px;
  text-align: center;
  @include breakpoint($max-small) {
    font-size: 15px;
    line-height: 19px;
  }
}

.mb-tp {
  margin-bottom: 36px;

  @include breakpoint($max-small) {
    margin-bottom: 5px;
  }
}

.panel-content-info {
  border: 1px solid black;
  padding: 10px 20px;
  border-radius: 5px;
}

.amountDiv-container-recap {
  background-color: $anem-background-badges-notifications;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  @include breakpoint($max-large){
    flex-wrap: wrap;
  }
  @include breakpoint($max-small){
    padding: 10px;
  }
}

.border-anem {
  border: 1px solid $anem-title;
}

.dotation-file-container {
  border: 1px solid $anem-button;
  border-radius: 5px;
  background-color: rgba(0, 78, 148, 0.2);
  padding: 50px 10px;
  cursor: pointer;

  .dotation-file-arrow-container {
    margin: 0 auto;
    border: 3px solid $anem-button;
    display: flex;
    width: 62px;
    height: 62px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}

/* ---------------------------------------------------
    PAGES STYLE
----------------------------------------------------- */
.page-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  @include breakpoint($max-small) {
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
}

/* ---------------------------------------------------
    OTHERS STYLE
----------------------------------------------------- */
.menu-contextuel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
  border: 1px solid white;
  color: black;

  &:hover {
    border-color: black;
  }

  & .menu-contextuel {
    display: none;
    bottom: 110%;
    right: 0;
    position: absolute;
    background-color: white;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);

    button {
      margin-bottom: 0 !important;
    }

    &.active {
      display: block;
    }
  }
}

.fa-ellipsis-size {
  font-size: 20px;
}

#legends {
  width: 100%;
  margin: auto 0;
}

.order-negative {
  order: -1;
}

/* ---------------------------------------------------
    FORM STYLE
----------------------------------------------------- */

.form-td {
  .incremental {
    display: flex;
    justify-content: space-evenly;
  }

  input {
    margin: 5px;
  }

  .submit {
    margin: 5px;
  }
}

.submit {
  .btn {
    border-radius: 0;
    background-color: $anem-button;
  }
}
