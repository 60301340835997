.page {
  width: 100%;
}

.input-clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  position: absolute;
  top: 5px;
  right: 5px;
}

.circle-legend{
  margin-right: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: inline-block;
}

.circle-legend-violet{
  @extend .circle-legend;
  background-color: $graph-violet;
}

.circle-legend-violet-declared{
  @extend .circle-legend;
  background-color: #6463A5;
}

.circle-legend-violet-validated{
  @extend .circle-legend;
  background-color: #9796BE;
}

.circle-legend-violet-allowed{
  @extend .circle-legend;
  background-color: #BDBDD1;
}

.circle-legend-violet-o-6{
  @extend .circle-legend;
  background-color: $graph-violet-o-6;
}
.circle-legend-violet-o-3{
  @extend .circle-legend;
  background-color: $graph-violet-o-3;
}

.circle-legend-darkblue{
  @extend .circle-legend;
  background-color: #004E94;
}

.circle-legend-green-o-3{
  @extend .circle-legend;
  background-color: rgba(165,73,27,0.6);
}

.circle-legend-orange-o-3{
  @extend .circle-legend;
  background-color: rgba(28,165,12,0.3);
}

.circle-legend-gris{
  @extend .circle-legend;
  background-color: $graph-gris;
}

.bg-bleu{
  background-color: $anem-section-rectangle;
}

.chartjs-render-monitor{
  @include breakpoint($max-medium){
    max-width: 250px;
    margin: 0 auto;
  }
}