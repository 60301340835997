.footer-top{
  background-color: #1D353F;
  padding: 30px 100px;
  @include breakpoint($max-medium){
    padding: 20px 10px;
  }

  .footer-top-title{
    @include font($font-roboto, 14px, white, 700);
    line-height: 20px;
    text-transform: uppercase;

    &:after{
      content: '';
      display: block;
      margin: 15px 0px 10px 0px;
      height: 1px;
      width: 25px;
      background-color: white;
    }
  }

  & ul{
    padding: 0;
    margin: 0;
    list-style: none;
  }

  & a{
    @include font($font-roboto, 14px, white, 300);
    line-height: 20px;

    &:hover{
      color: white;
      text-decoration: underline;
    }
  }
}



.footer-bottom{
  background-color: #172C34;

  .nav.nav-modified{
    justify-content: center;

    & a{
      @include font($font-roboto, 12px, white, 700);
      line-height: 14px;
      padding: 20px 10px;

      &:hover{
        color: white;
        text-decoration: underline;
      }
    }
  }
}