$hiddenOnlySmall: 0px $small;
$hiddenOnlyMedium: $min-small $medium;
$hiddenOnlyLarge: $min-medium $max-large;
$hiddenOnlyExtraLarge: $min-large $max-extra-large;


/***********************************************************/
/**********         Small            **********/
/***********************************************************/
@include breakpoint($min-small) {
  .hidden-s-up {
    display: none !important;
  }
}

@include breakpoint($hiddenOnlySmall) {
  .hidden-s-only {
    display: none !important;
  }
}


/***********************************************************/
/**********         Medium            **********/
/***********************************************************/
@include breakpoint($max-medium) {
  .hidden-medium-down {
    display: none !important;
  }
}

@include breakpoint($min-medium) {
  .hidden-medium-up {
    display: none !important;
  }
}

@include breakpoint($hiddenOnlyMedium) {
  .hidden-medium-only {
    display: none !important;
  }
}


/***********************************************************/
/**********         Large            **********/
/***********************************************************/
@include breakpoint($max-large) {
  .hidden-large-down {
    display: none !important;
  }
}

@include breakpoint($min-large) {
  .hidden-large-up {
    display: none !important;
  }
}

@include breakpoint($hiddenOnlyLarge) {
  .hidden-large-only {
    display: none !important;
  }
}

/***********************************************************/
/**********         Extra Large            **********/
/***********************************************************/
@include breakpoint($max-extra-large) {
  .hidden-el-down {
    display: none !important;
  }
}

@include breakpoint($min-extra-large) {
  .hidden-el-up {
    display: none !important;
  }
}

@include breakpoint($hiddenOnlyExtraLarge) {
  .hidden-el-only {
    display: none !important;
  }
}
