.popup-big-container {
  position: fixed;
  display: none;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;

  &.active {
    display: flex;
  }

  .popup-container {
    max-width: 50%;
    margin: 0 auto;
    background-color: white;
    padding: 20px;

    .popup-title {
      @include font($font-roboto, 14px, $anem-blue-header, 400);
      text-align: center;
    }

    .label-popup {
      @include font($font-roboto, 12px, $anem-blue-header, 400);
    }
  }


}