textarea:focus, input:focus, select:focus{
  outline: none;
}

.error-input .input-container,
.error-input.input-container {
  border-bottom-color: rgba(255, 0, 0, 0.4);

  & .input-after:after{
    background-color: rgba(255, 0, 0, 0.9);
  }
}

.input-container{
  border-bottom: 2px solid $input-border-color;
  position: relative;
  margin-bottom: 20px;

  @include breakpoint($max-small){
    margin-bottom: 5px;
  }

  &.picto-euro{
    position: relative;

    &:after{
      content: '€';
      position: absolute;
      top: 50%;
      right: -10px;
      transform: translateY(-50%);
      color: black;
    }
  }

  .input-after:after{
    content: "";
    box-sizing: border-box;
    width: 0;
    height: 2px;
    background-color: $anem-button;
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    will-change: width;
    transition: width .285s ease-out;
  }

  & input:focus ~ .input-after:after,
  & textarea:focus ~ .input-after:after {
    width: 100%;
  }
}

.error-input-text {
  @include font($font-roboto, 14px, rgba(255, 0, 0, 0.9), 400);
  ul {
    padding: 0;
    list-style: none;
  }
}

.spec-label{
  display: block;
  width: auto;
  white-space: nowrap;
}

input:not([type='checkbox']):not([type="radio"]){
  @include font($font-roboto, 14px, $anem-title, 400);
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  width: 100%;
  background-color: white;
  padding: 5.5px;
}

textarea {
  width: 100%;
  border: none;
}

.input-same-height {
  display: flex;
  align-items: flex-end;

  & .form-group {
    width: 100%;
  }
}

.picto-euro-input{
  position: relative;

  &:after{
    content: '€';
    position: absolute;
    top: 50%;
    right: 20px;
    color: black;
    font-size: 20px;
    transform: translateY(-50%);

    @include breakpoint($max-medium) {
      font-size: 15px;
    }
  }
}

.picto-percent-input{
  position: relative;

  &:after{
    content: '%';
    position: absolute;
    top: 50%;
    right: 20px;
    color: black;
    font-size: 20px;
    transform: translateY(-50%);

    @include breakpoint($max-medium) {
      font-size: 15px;
    }
  }
}

input.budget:not([type='checkbox']):not([type="radio"]) {
  background-color: white;@include font($font-roboto, 14px, $anem-title, 400);
  border: 1px solid $graph-gris;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 7px 10px;
  border-radius: 7px;
  font-size: 25px;

  @include breakpoint($max-medium) {
    padding: 7px 5px;
    font-size: 15px;
  }
}

input.disabled-arrow::-webkit-outer-spin-button,
input.disabled-arrow::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
    -moz-appearance:textfield;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}


select{
  @include font($font-roboto, 14px, $anem-title, 400);
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  width: 100%;
  background: url('/build/img/arrow-down.png') no-repeat right white;
  appearance:none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input::-ms-clear {
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  content: '';
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url('/build/img/datepicker.png') no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $anem-title;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.custom-checkbox {
  $size: 20px;
  position: relative;
  height: $size;
  line-height: $size;
  display: block;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 5px;

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
  span{
    display: flex;
    align-items: center;
  }

  span:before {
    content: '';
    padding-left: 1.5px;
    display: inline-block;
    height: $size;
    width: $size;
    border: 2px solid $input-border-color;
    border-radius: 4px;
    font-size: 10px;
    margin-right: 7px;
  }

  input + span:before {
    color: white;
    background-color: white;
  }
  input:hover + span:before {
    background-color: $anem-button;
    border-color: $anem-button;
  }
  input:checked + span:before {
    content: '\002714';
    background-color: white;
    border-color: $anem-button;
    color: black;
    font-size: 14px;
  }
}


.custom-checkbox-rect {
  position: relative;
  display: block;
  cursor: pointer;

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
  span{
    @include font($font-roboto, 14px, $anem-button, 500);
    position: relative;
    display: block;
    border: 2px solid $input-border-color;
    border-radius: 5px;
    padding: 10px 20px;
    transition: all 0.3s ease;
  }

  input:hover + span {
    border-color: $anem-button;
  }

  input:checked + span {
    background-color: $anem-button;
    border-color: $anem-button;
    color: white;
  }

  input:checked + span:after {
    font-family: "Font Awesome 5 Free";
    font-size: 23px;
    font-weight: 700;
    content: '\f00d';
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    color: white;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }
}


.custom-radio {
  $size: 20px;
  position: relative;
  height: $size;
  line-height: $size;
  display: block;
  cursor: pointer;

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
  span{
    display: flex;
    align-items: center;
  }

  span:before {
    content: '';
    padding-left: 1.5px;
    display: inline-block;
    height: $size;
    width: $size;
    border: 2px solid $input-border-color;
    border-radius: 50%;
    font-size: 10px;
    margin-right: 7px;
  }

  input + span:before {
    color: white;
    background-color: white;
  }

  input:hover + span:before {
    background-color: $anem-button;
    border-color: $anem-button;
  }

  input:checked + span:before {
    content: '';
    background-color: $anem-button;
    border-color: $anem-button;
  }
}

.flex-wrap-resp{
  display: flex;

  @include breakpoint($max-medium){
    flex-wrap: wrap;
  }

  & .width-resp{
    padding-left: 15px;
    @include breakpoint($max-medium){
      width: 100%;
      padding: 0px;
    }
  }
}

.js-datepicker{
    background-image: url('/build/img/datepicker.png');
    background-repeat: no-repeat;
    background-position: right 5px center;
    &::-webkit-calendar-picker-indicator{
      display: none;
    }
    &::-webkit-input-placeholder{
      visibility: hidden !important;
    }
}

.amountDiv-container {
  text-align: center;
  border: 1px solid $green-accepter;
  padding: 7px 20px;
  display: block;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-bottom: 20px;

  .amountDiv-input-container{
    position: relative;
    display: inline-block;

    &:after{
      content: '€';
      position: absolute;
      top: 48%;
      right: 0px;
      transform: translateY(-50%);
      color: black;
    }
  }

  & input:not([type='checkbox']):not([type="radio"]){
    width: auto;
  }
}



/**
  SWITCH CHECKBOX
 */
.options-checkbox {
  position: relative;
  min-width: 60px;
  height: 25px;
  border-radius: 50px;
  background: #F4F6F7;
  text-align: center;
  color: #626567;
  display: flex;
  border: 2px solid #F4F6F7;
  font-size: 13px;

  .option {
    display: flex;
    justify-content: center;
    flex: 1 1 auto;
    align-items: center;
    height: 100%;
    position: relative;
    z-index: 2;
    cursor: pointer;
    -webkit-transition: .4s;
    transition: .4s;
    color: white;

    &:first-child{
      color: white;
    }
    &:last-child{
      color: #626567;
    }
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    visibility: hidden;

    &:checked + .slider {
      transform: translateX(100%);
      background: $anem-blue-header;
    }

    &:checked ~ .option {
      color: #626567;
    }

    &:checked ~ .option:last-child {
      color: white;
    }
  }

  .slider {
    position: absolute;
    left: 0px;
    height: 100%;
    width: 50%;
    background-color: $red-refuser;
    z-index: 1;
    border-radius: 50px;
    -webkit-transition: .4s;
    transition: .4s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0 !important;
  }
}

input[type='number'] {
    -moz-appearance: textfield !important;
}
