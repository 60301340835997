.btn-white{
  border-color: white;
  color: white;

  &:hover{
    color: white;
  }

  &:focus{
    color: white;
  }
}

.navbar {
  background: $anem-blue-header;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar.navbar-modified{
  padding: 0px;
  margin-bottom: 0px;
  height: 75px;
}

.container-fluid .navbar-collapse-modified{
  @include breakpoint($max-medium){
    display: flex;
  }
}

.navbar .container-fluid-modified{
  padding: 0px;
  flex-wrap: nowrap;
}

.navbar-info-container{
  display: flex;
  cursor: pointer;
}

.navbar-height-flex{
  height: 100%;
  display: flex;
  align-items: center;
}

.navbar-info-big-container{
  height: 75px;
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
  margin-left: auto;

  @include breakpoint($max-medium){
    margin-right: 0.5rem;
  }

.navbar-info-left{
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.navbar-info-middle{
  padding: 0px 5px 0 10px;
  line-height: 20px;
  @include breakpoint($max-small){
    text-align: center;
  }
}

.navbar-info-right{
  color: white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 5px 0px 5px;
  @include breakpoint($max-small){
    align-items: center;
  }
}

.navbar-info-nomprenom{
  @include font(false, 14px, white, 400);

  & span{
    text-transform: uppercase;
  }
}

.navbar-info-function{
  @include font(false, 14px, white, 200);
  font-style: italic;
}
}

.navbar-notifications-container{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  height: 75px;
  width: 75px;
  cursor: pointer;

  #no-notification{
    margin-left: 10px;
  }

  & .dropdown-menu{
    right: 0px;
    left: auto;

    @include breakpoint($max-small) {
      position: fixed;
      top: 75px;
      right: 0;
      left: 0px;
      a {
        white-space: inherit;
      }
    }
  }

  & .dropdown-toggle-reset {
    &:after {
      display: none;
    }
  }
}

.bell-notifications{
  position: relative;
  color: white;

  & .bell-notification-badges{
    @include font($font-roboto, 10px, #1C353F, 400);
    border-radius: 50%;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 14px;
    width: 14px;
    background-color: $anem-background-badges-notifications;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}