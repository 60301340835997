$anem-background: #E4E4E4;
$anem-blue-header: #004E94;
$anem-title: #1C353F;
$anem-button: #004E94;
$anem-link: #94CFF8;
$anem-background-badges-notifications: #C6DFEE;
$anem-footer-contact: #94CFF8;
$anem-footer-contact-text: #004E94;
$anem-button-text: #FFFFFF;
$anem-header-link: #94CFF8;
$anem-form-label: #1C353F;
$anem-user-title: #FFFFFF;
$anem-text: #1C353F;
$anem-sidebar: #003463;
$anem-sidebar-ul-border: #FFFFFF;
$anem-bottom-footer: #172C34;
$anem-footer-menu: #FFFFFF;
$anem-section-rectangle: #C6DFEE;
$anem-graph-high: #af2cff;
$anem-graph-medium: #fb63ff;
$anem-graph-low: #d36dff;
$nav-h1-lineheight: 19px;
$nav-h1-font-size: 14px;
$nav-link-font-size: 14px;
$nav-link-font-lineheight: 16px;
$input-border-color: #C6DFEE;
$anem-realise-color: #ABDF1E;
$anem-supprime-color: #7F7F7F;
$green-accepter : #28a745;
$red-refuser : #dc3545;
$orange-attente: #e0991e;
$graph-violet: rgba(100, 99, 165, 1);
$graph-violet-o-6: rgba(100, 99, 165, 0.6);
$graph-violet-o-3: rgba(100, 99, 165, 0.3);
$graph-gris: rgba(228, 228, 228, 1);

$font-ubuntu: 'Ubuntu', sans-serif;
$font-roboto: 'Roboto', sans-serif;

// Breakpoint
//$extra-small: 0px;

$small: 425px;
$max-small: max-width $small;
$min-small: min-width $small + 1;

$medium: 768px;
$max-medium: max-width $medium;
$min-medium: min-width $medium + 1;

$large: 1024px;
$max-large: max-width $large;
$min-large: min-width $large + 1;

$extra-large: 1440px;
$max-extra-large: max-width $extra-large;
$min-extra-large: min-width $extra-large + 1;

