.separator-sidebar{
  content: '';
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 0px;
  height: 1px;
  background-color: white;
}


#sidebar .sidebar-header {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  background: $anem-sidebar;
  position: relative;
  &:after{
    @extend .separator-sidebar;
  }
}

$sidebar-width: 200px;
#sidebar {
  min-width: $sidebar-width;
  max-width: $sidebar-width;
  background: $anem-sidebar;
  color: $anem-button-text;
  //transition: all 0.3s;
  overflow: hidden;

  @include breakpoint($max-medium){
    min-width: 80px;
    max-width: 80px;
    margin-left: -80px !important;
  }
  @include breakpoint($max-small){
    position: absolute;
    z-index: 99;
    top: 75px;
    left: -100%;
    bottom: 0;
    right: 0;
  }
}

#sidebar.active {
  min-width: $sidebar-width;
  max-width: $sidebar-width;
  //margin-left: -$sidebar-width;

  @include breakpoint($min-medium) {
    margin-left: 0 !important;
  }

  @include breakpoint($max-medium){
    margin-left: 0 !important;
  }

  @include breakpoint($max-small){
    position: absolute;
    z-index: 99;
    top: 75px;
    left: 0;
    bottom: 0;
    right: 0;
    width: auto;
    min-width: 100%;
    max-width: 100%;
  }
}

.menu-block-sidebar{
  position: relative;
  padding: 30px 10px 20px 10px;

  &:not(:last-child):after{
    @extend .separator-sidebar;
  }

  .menu-title-sidebar{
    @include font($font-ubuntu, 16px, white, 700);
    line-height: 22px;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  & ul{
    list-style: none;
    padding: 0;
    margin: 0;
  }

  & li:not(:last-child){
    margin-bottom: 15px;
  }

  & a {
    @include font(false, 16px, white, 300);
    line-height: 19px;
    text-decoration: none;

    &:hover{
      text-decoration: underline;
      color: white;
    }

    &.active{
      color: $anem-link;
    }
  }
}