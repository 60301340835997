@mixin font($font: false, $size: false, $colour: false, $weight: false) {
  @if $font {font-family: $font, sans-serif;}
  @if $size { font-size: $size; }
  @if $colour { color: $colour; }
  @if $weight { font-weight: $weight; }
}

@mixin box-shadow($params) {
  -webkit-box-shadow: $params;
  -moz-box-shadow: $params;
  box-shadow: $params;
}