.btn-anem-submit{
  @include font($font-roboto, 18px, white, 400);
  text-transform: uppercase;
  line-height: 21px;
  display: inline-block;
  padding: 10px 20px;
  background-color: $anem-button;
  border: 1px solid $anem-button;
  transition: border-radius 0.3s ease;

  &:hover{
    border-radius: 5px;
  }

  &:focus{
    outline: none;
  }
}

.btn-anem-submit-inversed{
  @include font($font-roboto, 18px, $anem-button, 400);
  background-color: white;
  border: 1px solid $anem-button;
}

.btn-anem{
  @include font($font-roboto, 16px, white, 400);
  line-height: 18px;
  display: inline-block;
  padding: 8px 15px;
  background-color: $anem-button;
  border: 1px solid $anem-button;
  //border-radius: 5px;

  &:hover{
    color: white;
  }

  &:focus{
    outline: none;
  }

  &:disabled{
    background-color: #A2A2A2;
    border: 1px solid #A2A2A2;
  }
}

.btn-anem-small{
  @include font($font-roboto, 14px, white, 400);
  line-height: 16px;
  display: inline-block;
  padding: 5px 10px;
  background-color: $anem-button;
  border: 1px solid $anem-button;
  border-radius: 5px;

  &:hover{
    color: white;
  }

  &:focus{
    outline: none;
  }
}

.svg-delete{
  &:hover svg g{
    fill: red;
  }
}
.svg-edit{
  &:hover svg g{
    fill: $anem-blue-header;
  }
}

.btn-statut{
  display: inline-block;
  padding: 7px 15px;
  color: white;
  border-radius: 5px;
  transition: all 0.3s ease;
  min-width: 90px;

  &:focus {
    outline: none;
  }

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &.btn-statut-refuser{
    border: 1px solid $red-refuser;
    background-color: $red-refuser;
    &:hover{
      background-color: white;
      color: $red-refuser;
    }
  }
  &.btn-statut-accepter{
    border: 1px solid $green-accepter;
    background-color: $green-accepter;
    &:hover{
      background-color: white;
      color: $green-accepter;
    }
  }
}