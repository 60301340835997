.table-modified{
  padding-bottom: 20px;
  overflow-x: auto;
  width: 100%;

  & .navigation-link{
    color: $anem-blue-header;
    text-decoration: underline;

    &:hover{
      color: $anem-sidebar;
    }
  }

  & thead tr th{
    border-top: none;
  }

  @include breakpoint($max-large){
    display: block;
  }

  & tr th:first-child{
    padding-left: 50px;
     &.clickable-element{
      text-decoration: underline;
    }
  }

  & tbody tr th{
    font-weight: 400;
     &.clickable-element{
      cursor: pointer;
    }
  }
}

.table-history {
  thead th {
    border: none;
  }
  tfoot tr {
    background-color: $anem-blue-header;
    color: white;
  }
}
